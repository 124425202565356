.twoCardsParent {
  min-height: 500px;
  @screen md {
    gap: 3vw;
  }

  @apply flex flex-col md:flex-row
}

.twoCardsChild {
  @extend .paddingBetweenElements;
  @extend .cardColor;
  @extend .cardHover;
  @extend .genericCardPadding;

  flex: 1;
  border-radius: 16px;
  justify-content: space-between;

  @apply flex flex-col
}

.horizontalImageCard {
  @extend .paddingBetweenElements;
  @extend .cardColor;
  @extend .cardHover;

  flex: 1;
  border-radius: 16px;
  justify-content: space-between;
  min-height: 500px;

  .cardImage {
    @apply my-auto px-[2.5rem]
  }

  @apply flex flex-row
}

.cardHover {
  &:hover {
    .cardLinkContainer {
      background-color: #528FCE;
      color: white;
    }

    .cardImage {
      @apply scale-105 duration-1000
    }
  }
}

.horizontalImageCardTextDiv {
  @extend .genericCardPadding;

  @apply flex flex-col
}

.horizontalImageCardTextDivWithClickText {
  @extend .genericCardPadding;

  @apply flex flex-col justify-between
}

.cardColor {
  color: #333333;
  background-color: #F5F5F5
}

.cardTitle {
  font-size: 21px;
  line-height: 24px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
  }
}

.cardImage {

}

.cardDescription {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    font-weight: 300;
  }

  @apply py-4
}

.cardLinkContainer {
  font-size: 20px;
  line-height: 32px;
  @apply flex gap-2 mt-2 py-1 pr-2 pl-3 rounded-3xl
}

.cardLinkIcon {
  @apply h-4 md:h-5 self-center
}

.genericCardPadding {
  @apply px-6 py-6 md:px-10 md:py-10
}

.paddingBetweenElements {
  @apply my-6 md:my-10
}

